import create from 'zustand';
import { devtools } from 'zustand/middleware';

import { FoodBoxOrderFormState } from './order-form.types';
import { FoodBoxDeliveryDayInfo, FoodBoxDeliveryWeekInfo, FoodBoxCartItemDto, FoodBoxOrderPricingSummaryDto } from '../../types/food-box.types';
import { Lookup } from '../../types/lookup-store.types';
import { AddressDto } from '../../types/order-dto.types';
import { UserBasicDetails, UserSubscriptionDetails } from '../../types/user-dto.types';
import { PostcodeCheckDto } from '../../types/postcode-dto.types';

const store = (set: any, get: any) => ({
  // values
  isDrawerShowing: false,

  valid: false,
  orderDeliveryWeek: 0, // This will be the selected delivery week from the <DeliveryWeekDropdown>. By default its the 1st week option
  selectedDeliveryWeekInfo: {}, // Week range info
  selectedDeliveryDayInfo: {}, // Day info
  personalInfoFormValidation: {
    name: false,
    email: false,
  },
  deliveryDetailsFormValidation: {
    phoneNumber: false,
    address: false,
    addressDisplayName: false,
    deliveryInstructions: false,
    deliveryTime: false
  },

  selectedFilters: [],

  cart: [],
  initialCart: [],
  discountCode: "",

  billingAddressSameAsDelivery: true,

  // actions: setters
  setDrawerShowing: (show: boolean) => set(() => ({
    isDrawerShowing: show
  })),
  setValid: (valid: boolean) => set(() => ({
    valid: valid
  })),
  setDeliveryDetailsValid: (field: string, valid: boolean) => set((state: FoodBoxOrderFormState) => ({
    deliveryDetailsFormValidation: {
      ...state.deliveryDetailsFormValidation,
      [field]: valid
    }
  })),
  setPersonalInfoValid: (field: string, valid: boolean) => set((state: FoodBoxOrderFormState) => ({
    personalInfoFormValidation: {
      ...state.personalInfoFormValidation,
      [field]: valid
    }
  })),
  setReferralSelectionValid: (valid: boolean) => set((state: FoodBoxOrderFormState) => ({
    referralDetailsFormValidation: valid
  })),
  setOrderDeliveryWeek: (week: number) => set(() => ({
    orderDeliveryWeek: week
  })),
  setSelectedDeliveryDayIndex: (day: string) => set(() => ({
    selectedDeliveryDayIndex: day
  })),
  setSelectedDeliveryWeekInfo: (weekInfo: FoodBoxDeliveryWeekInfo) => set(() => ({
    selectedDeliveryWeekInfo: weekInfo
  })),
  setSelectedDeliveryDayInfo: (dayInfo: FoodBoxDeliveryDayInfo) => set(() => ({
    selectedDeliveryDayInfo: dayInfo
  })),

  // User details
  setUserBasicDetails: (userDetails: UserBasicDetails) => set(() => ({
    firstName: userDetails.firstName,
    lastName: userDetails.lastName
  })),
  setEmail: (email: string) => set(() => ({
    email: email
  })),
  setReferral: (referral: string) => set(() => ({
    referral: referral
  })),
  setReferralSecondOption: (referralSecondOption: string) => set(() => ({
    referralSecondOption: referralSecondOption
  })),
  setSubscriptionDetails: (subscriptionDetails: UserSubscriptionDetails) => set((state: FoodBoxOrderFormState) => ({
    subscribeToNewsletter: subscriptionDetails.subscribeToNewsletter,
    emailMarketingConsentMessage: subscriptionDetails.emailMarketingConsentMessage,
    emailMarketingConsentDate: subscriptionDetails.emailMarketingConsentDate,
    subscribeToSmsMarketing: subscriptionDetails.subscribeToSmsMarketing,
    smsMarketingConsentMessage: subscriptionDetails.smsMarketingConsentMessage,
    smsMarketingConsentDate: subscriptionDetails.smsMarketingConsentDate,
  })),
  resetPersonalDetails: () => set(() => ({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    subscribeToNewsletter: undefined,
    emailMarketingConsentMessage: undefined,
    emailMarketingConsentDate: undefined,
    subscribeToSmsMarketing: undefined,
    smsMarketingConsentMessage: undefined,
    smsMarketingConsentDate: undefined,
    personalInfoFormValidation: {
      name: false,
      email: false,
    },
  })),

  // Delivery details
  setPostcodeGroupId: (postcodeGroupId: string) => set(() => ({
    postcodeGroupId: postcodeGroupId
  })),
  setPostcode: (postcode: string) => set(() => ({
    postcode
  })),
  setPostcodeDetails: (postcodeDetails: PostcodeCheckDto) => set(() => ({
    postcodeDetails
  })),
  setIsNationalPostcode: (isNational: boolean) => set(() => ({
    isNationalPostcode: isNational
  })),

  setDeliveryAddress: (address?: AddressDto) => set(() => ({
    deliveryAddress: address
  })),
  setAddressDisplayName: (displayName?: string) => set(() => ({
    addressDisplayName: displayName
  })),
  setDeliveryInstructions: (deliveryInstructions?: string) => set(() => ({
    deliveryInstructions: deliveryInstructions
  })),
  setIsTimedDelivery: (isTimedDelivery?: boolean) => set(() => ({
    isTimedDelivery: isTimedDelivery
  })),
  setIsEveningDelivery: (isEveningDelivery?: boolean) => set(() => ({
    isEveningDelivery: isEveningDelivery
  })),
  setPhoneNumber: (phoneNumber?: string) => set(() => ({
    phoneNumber: phoneNumber
  })),
  resetDeliveryDetails: () => set(() => ({
    deliveryAddress: undefined,
    addressDisplayName: undefined,
    deliveryInstructions: undefined,
    phoneNumber: undefined,
    isTimedDelivery: undefined,
    isEveningDelivery: undefined,
    deliveryDetailsFormValidation: {
      phoneNumber: false,
      address: false,
      addressDisplayName: false,
      deliveryInstructions: false,
      deliveryTime: false
    },
    deliveryInfoId: undefined,
  })),

  updateFilters: (filter: Lookup) => set((state: FoodBoxOrderFormState) => ({
    selectedFilters: [...state.selectedFilters, { ...filter }],
  })),
  removeFilter: (filter: Lookup) => set((state: FoodBoxOrderFormState) => {
    const removedFilters = state.selectedFilters.filter(sf => sf.id !== filter.id)
    return ({
      selectedFilters: [...removedFilters],
    })
  }),
  updatePreference: (preference: Lookup) => set(() => ({
    selectedPreference: preference,
  })),
  resetFilters: () => set(() => ({
    selectedFilters: [],
  })),
  resetPreference: () => set(() => ({
    selectedPreference: undefined,
  })),

  addItemToCart: (cartItem: FoodBoxCartItemDto) => set((state: FoodBoxOrderFormState) => {
    const currentCart = state.cart;

    // check if same variation item already exists for the same day
    const existingVariation = currentCart.find(item => item.date === cartItem.date && item.itemId === cartItem.itemId);
    if (existingVariation) {
      existingVariation.quantity++;
    } else {
      currentCart.push(cartItem);
    }

    return ({
      cart: [...currentCart]
    });
  }),
  updateItemCountInCart: (cartItem: FoodBoxCartItemDto, newQuantity: number) => set((state: FoodBoxOrderFormState) => {
    const currentCart = state.cart;

    // check if same variation item already exists for the same day
    const existingVariation = currentCart.find(item => item.date === cartItem.date && item.itemId === cartItem.itemId);
    if (existingVariation) {
      existingVariation.quantity = newQuantity;
    }

    return ({
      cart: [...currentCart]
    });
  }),
  removeItemFromCart: (cartItem: FoodBoxCartItemDto) => set((state: FoodBoxOrderFormState) => {
    const currentCart = state.cart;
    const updatedCart = currentCart.filter(item => !(item.menuItemId === cartItem.menuItemId && item.itemId === cartItem.itemId && item.date === cartItem.date));

    return ({
      cart: [...updatedCart]
    });
  }),
  resetCart: () => set(() => ({
    cart: [],
  })),
  setCart: (cartArr: FoodBoxCartItemDto[]) => set(() => ({
    cart: JSON.parse(JSON.stringify(cartArr)),
  })),
  setInitialCart: (cartCopy: FoodBoxCartItemDto[]) => set(() => ({
    initialCart: JSON.parse(JSON.stringify(cartCopy)),
  })),

  setDiscountCode: (code: string) => set(() => ({
    discountCode: code,
  })),
  setNewOrderId: (id: string) => set(() => ({
    newOrderId: id,
  })),
  resetAllData: () => set(() => ({
    valid: false,
    postcode: undefined,
    postcodeGroupId: undefined,
    orderDeliveryWeek: 0,
    selectedDeliveryDayIndex: undefined,
    selectedDeliveryWeekInfo: {},
    selectedDeliveryDayInfo: {},
    selectedFilters: [],
    selectedPreference: undefined,
    cart: [],
    discountCode: "",
    userId: undefined,
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    subscribeToNewsletter: undefined,
    emailMarketingConsentMessage: undefined,
    emailMarketingConsentDate: undefined,
    subscribeToSmsMarketing: undefined,
    smsMarketingConsentMessage: undefined,
    smsMarketingConsentDate: undefined,
    personalInfoFormValidation: {
      name: false,
      email: false,
    },
    deliveryAddress: undefined,
    addressDisplayName: undefined,
    deliveryInstructions: undefined,
    phoneNumber: undefined,
    isTimedDelivery: undefined,
    deliveryDetailsFormValidation: {
      phoneNumber: false,
      address: false,
      addressDisplayName: false,
      deliveryInstructions: false,
    },
    customerNo: undefined,
    pricingSummary: undefined,
    referral: undefined,
    referralSecondOption: undefined
  })),
  setBillingAddress: (address?: AddressDto) => set(() => ({
    billingAddress: address
  })),
  setIsBillingAddressSameAsDelivery: (isBillingAddressSameAsDelivery: boolean) => set(() => ({
    billingAddressSameAsDelivery: isBillingAddressSameAsDelivery
  })),
  setIsBillingAddressValid: (isBillingAddressValid: boolean) => set(() => ({
    isBillingAddressValid
  })),
  setIsAdminRequest: (isAdminRequest: boolean) => set({
    isAdminRequest: isAdminRequest
  }),
  setPricingSummary: (pricingSummary: FoodBoxOrderPricingSummaryDto) => set({
    pricingSummary
  }),
  setCustomerNo: (customerNo: number) => set({
    customerNo
  }),
  setDeliveryInfoId: (deliveryInfoId: string) => set({
    deliveryInfoId,
  }),
  setUserId: (userId: string) => set(() => ({
    userId
  })),
});

// @ts-ignore
export const useFoodBoxOrderFormStore = create<FoodBoxOrderFormState>()(devtools(store, { name: 'FoodBoxOrderFormState' }));